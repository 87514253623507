<template>
  <div class="contents">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12 mb-2 sticky">
          <div class="breadcrumb-main p-3" style="background-color: #f4f5f7 !important">
            <h4 class="text-capitalize breadcrumb-title">
              All Questions
              <ul class="atbd-breadcrumb nav">
                <li class="atbd-breadcrumb__item">
                  <router-link to="/">
                    <span class="la la-home"></span>
                  </router-link>
                  <span class="breadcrumb__seperator">
                    <span class="la la-slash"></span>
                  </span>
                </li>
                <li class="atbd-breadcrumb__item">
                  <span class="bread-item" style="color: #9299b8">Questions</span>
                  <span class="breadcrumb__seperator">
                    <span class="la la-slash"></span>
                  </span>
                </li>
                <li class="atbd-breadcrumb__item">
                  <span class="bread-item">All</span>
                </li>
              </ul>
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">

      <div class="col-xl-6 col-xxl-6 col-ssm-12 mb-30">
        <div class="ap-po-details p-25 radius-xl bg-white d-flex justify-content-between rel-pos">
          <div>
            <div class="overview-content">
              <h1>{{ stat.total || 0 | humanNumber }}</h1>
              <p> Pending</p>
            </div>
          </div>
          <div class="side-icon2">
            <img src="@/assets/img/custom/ques.gif" width="80" />
          </div>
        </div>
        <!-- </router-link> -->
      </div>

      <div class="col-xl-6 col-xxl-6 col-ssm-12 mb-30">
        <!-- <router-link to="/stat/classes"> -->
        <div class="ap-po-details p-25 radius-xl bg-white d-flex justify-content-between rel-pos">
          <div>
            <div class="overview-content">
              <h1>{{ stat.successful || 0 | humanNumber }}</h1>
              <p> Approved</p>
            </div>
          </div>
          <div class="side-icon2">
            <img src="@/assets/img/custom/ques.gif" width="80" />
          </div>
        </div>
        <!-- </router-link> -->
      </div>
    </div>
    </div>

    <div class="container-fluid overlay-clipped">
      <div class="row">
        <LoaderOverlay :active="true" v-if="!asyncLoader && loadingQuestions" :zIndex="99" position="absolute"
          style="height: 100%" />

        <div class="col-lg-12">
          <div
            class="userDatatable orderDatatable global-shadow border py-30 px-sm-30 px-20 bg-white radius-xl w-100 mb-30">
            <div class="project-top-wrapper d-flex justify-content-between flex-wrap mb-25 mt-n10">
              <div class="d-flex align-items-center flex-wrap w-100p col-sm-12">
                <form @submit.prevent="search" class="d-flex align-items-center mt-10">
                  <div class="project-search order-search global-shadow">
                    <div class="order-search__form">
                      <i class="las la-search"></i>
                      <input class="form-control mr-sm-2 border-0 box-shadow-none" type="search"
                        placeholder="Search by description" aria-label="Search" v-model="queryString" />
                    </div>
                  </div>
                  <button class="btn btn-primary btn-rounded ml-2" type="submit" v-if="!resetFlag">
                    Go
                  </button>
                </form>
                <button class="btn btn-primary btn-rounded ml-2 mt-2" type="button" @click="resetSearch"
                  v-if="resetFlag">
                  Reset
                </button>

                <!-- End: .project-search -->
                <div class="project-category d-flex align-items-center ml-md-30 mt-xl-10 mt-15">
                  <p class="fs-14 color-gray text-capitalize mb-10 mb-md-0 mr-10">
                    Status :
                  </p>
                  <div class="project-tap order-project-tap global-shadow">
                    <ul class="nav px-1" id="ap-tab" role="tablist">
                      <li class="nav-item d-flex align-items-center" @click="
  params = { level: 2048, status: 0 };
level = 2048;
status = 0;
                      ">
                        <a class="nav-link active" id="activity-tab" data-toggle="pill" href="#activity" role="tab"
                          aria-controls="activity" aria-selected="false">Pending</a>
                        <span class="badge badge-primary mr-2 br-5">{{
                            stat.pending | humanNumber
                        }}</span>
                      </li>
                      <li class="nav-item d-flex align-items-center" @click="
  params = { level: 2048, status: 1 };
level = 2048;
status = 1;
                      ">
                        <a class="nav-link" id="timeline-tab" data-toggle="pill" href="#timeline2" role="tab"
                          aria-controls="timeline" aria-selected="false">Approved</a>
                        <span class="badge badge-primary bg-green br-5">{{
                            stat.successful | humanNumber
                        }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="flex-1"></div>
                <page-size @sizeChange="sizeChange" />

                <!-- End: .project-category -->
              </div>

              <!-- End: .d-flex -->
              <div class="content-center mt-10"></div>
              <!-- End: .content-center -->
            </div>
            <div>
              <div class="row">
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label>Select class</label>
                    <v-select v-model="selectedClass" item-text="nick" item-value="cllid" label="nick"
                      :options="classes" @input="classSelected"></v-select>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3" v-if="selectedClass">
                  <div class="form-group">
                    <label>Select subject</label>
                    <v-select v-model="selectedSubject" item-text="name" item-value="sulid" label="name"
                      :options="availableSubjects" @input="getTopics"></v-select>
                  </div>
                </div>
                <div class="col-sm-12 col-md-5" v-if="selectedSubject">
                  <div class="form-group">
                    <label>Select topic</label>
                    <v-select v-model="selectedTopic" item-text="name" item-value="tolid" label="name" :options="topics"
                      :disabled="topicLoading" :loading="topicLoading">
                      <template #spinner="{ loading }">
                        <div v-if="loading" style="border-left-color: rgba(88, 151, 251, 0.71)" class="vs__spinner">
                          spinner
                        </div>
                      </template>
                    </v-select>
                  </div>
                </div>
                <div>
                  <div class="form-group">
                    <label>.</label>
                    <button class="btn btn-primary btn-rounded ml-2" type="button" @click="filterQuestion"
                      v-if="selectedClass && selectedSubject">
                      Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- End: .project-top-wrapper -->
         
            <div class="table-responsive mt-3">
              <table class="table mb-0 table-hover table-borderless border-0" v-if="questions.length">
                <thead>
                  <tr class="userDatatable-header">
                    <th v-for="(header, k) in headers" :key="k">
                      <span class="userDatatable-title">{{ header }}</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(q, k) in questions" :key="k">
                    <td v-if="paginationData">
                      <div class="orderDatatable-title">
                        <a class="link">{{ paginationData.offset + k + 1 }}</a>
                      </div>
                    </td>
                    <td v-else>
                      <div class="orderDatatable-title">
                        <a class="link">{{ k + 1 }}</a>
                      </div>
                    </td>
                    <td>
                      <div class="orderDatatable-title">
                        <a class="link" @click="
                          setCurrentQlid(q.qlid, q.tolid, params, getTopicName(q.tolid))
                        ">{{ q[headers[1]] }}</a>
                      </div>
                    </td>
                    <td>
                      <div class="userDatatable-content">
                        {{ questionTypeMap[q[headers[2]]] }}
                      </div>
                    </td>
                    <td>
                      <div class="orderDatatable-title d-inline-block">
                        <span
                          class="bg-opacity-primary color-primary rounded-pill userDatatable-content-status active">{{
                              q[headers[3]]
                          }}</span>
                      </div>
                    </td>
                    <td>
                      <div class="orderDatatable-status d-inline-block">
                        <span class="order-bg-opacity-info rounded-pill active"
                          :class="progressColour[q[headers[4]]]">{{ progress[q[headers[4]]] }}</span>
                      </div>
                    </td>
                    <td>
                      <ul class="orderDatatable_actions mb-0 d-flex flex-wrap float-right">
                        <li>
                          <a @click="
                            setCurrentQlid(
                              q.qlid,
                              q.ulid,
                              params,
                              getTopicName(q.tolid),
                              true
                            )
                          " class="view link" v-tooltip:bottom="'Review Question'">
                            <i class="las la-door-open"></i>
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <!-- End: tr -->
                </tbody>
              </table>
            </div>
            <no-content v-if="noData" />
          </div>
          <!-- End: .userDatatable -->
        </div>

        <!-- End: .col -->
      </div>
    </div>
    <Pagination :url="questionUrl" @pageChange="pageChange" @loading="loading" :urlParam="urlParam" :size="size"
      v-if="showFilteredResult" />
  </div>
</template>

<script>
import { questionTypeMap, progress, progressColour } from "@/utilities/constant";
import Pagination from "@/components/Pagination.vue";
import NoContent from "@/components/NoContent";
import LoaderOverlay from "@/components/LoaderOverlay.vue";
import { mapGetters, mapState } from "vuex";
import PageSize from "../../../components/PageSize.vue";
import { apiClient } from "../../../plugins/restclient";
import { arrayFromObject } from "@/utilities";
import dbSubjects from "../../../assets/json/dbSubjects.json"

export default {
  components: {
    Pagination,
    LoaderOverlay,
    NoContent,
    PageSize,
  },
  data() {
    return {
      questions: [],
      questionTypeMap,
      progress,
      progressColour,
      totalPage: 0,
      currentPage: 0,
      size: 10,
      paginationData: "",
      loadingQuestions: false,
      noData: false,
      headers: ["No", "description", "type", "version", "status", "action"],
      params: {
        level: 2048,
        status: 0,
      },
      status: 0,
      level: 2048,
      topicsMeta: {},
      stat: {},
      queryString: "",
      oldQueryString: "",
      urlParam: "",
      resetFlag: false,
      selectedSubject: null,
      selectedTopic: null,
      selectedClass: null,
      availableSubjects: [],
      topics: [],
      topicLoading: false,
      showFilteredResult: false,
    };
  },
  metaInfo: {
    title: "Greater Favour",
    titleTemplate: "%s | Review",
  },
  computed: {
    ...mapState(["asyncLoader", "userInterest"]),
    ...mapGetters({
      subjects: "allSubjects",
      classes: "allClasses",
    }),
    questionUrl() {
      return `/v1/office/assessment/question/summon`;
    },
  },
  watch: {
    status() {
      this.resetSearch();
      this.urlParam = `&status=${this.status}&level=${this.level}`;
    },
    level() {
      this.resetSearch();
      this.urlParam = `&status=${this.status}&level=${this.level}`;
    },
    queryString() {
      if (this.oldQueryString != this.queryString) {
        this.resetFlag = false;
      }
    },
    selectedClass(nval) {
      if (!nval) {
        this.selectedTopic = null;
        this.selectedSubject = null
        this.topics = []
      }
    },
  },
  created() {
    this.getStat();
    this.urlParam = `&status=${this.status}&level=${this.level}`;
  },
  methods: {
    getTopics() {
      if (!this.selectedSubject) {
        return;
      }

      this.topics = [];
      this.topicLoading = true;
      apiClient
        .get(`v1/office/frame/topic/fetch/subject/${this.selectedSubject.sulid}`)
        .then((res) => {
          this.topicLoading = false;
          this.topics = res.data.data;
          this.selectedTopic = null;
        })
        .catch(() => {
          this.topicLoading = false;
          // console.log(err);
        });
    },
    filterQuestion() {
      this.urlParam = `&status=${this.status}&level=${this.level}`;
      if (this.selectedSubject) {
        this.urlParam += `&subject=${this.selectedSubject.sulid}`;
      }
      if (this.selectedClass) {
        this.urlParam += `&class=${this.selectedClass.cllid}`;
      }
      if (this.selectedTopic) {
        this.urlParam += `&topic=${this.selectedTopic.tolid}`;
      }
      this.showFilteredResult = true;
    },
    classSelected() {
      this.availableSubjects = this.subjects.filter(s => s.cllid == this.selectedClass?.cllid)
      this.selectedSubject = null;
    },
    resetSearch() {
      this.queryString = "";
      this.oldQueryString = "";

      this.urlParam = `&status=${this.status}&level=${this.level}`;
      this.resetFlag = false;
    },
    search() {
      if (!this.queryString.length) {
        return;
      }
      this.oldQueryString = this.queryString;

      this.resetFlag = true;
      this.urlParam = `&status=${this.status}&level=${this.level}&q=${this.queryString}`;
    },
    getStat() {
      apiClient
        .get("/v1/office/assessment/question/stat")
        .then((res) => {
          this.stat = {
            ...res.data.info.review,
          };
        })
        .catch(() => { });
    },
    setCurrentQlid(qlid, ulid, qLevel, topicName, newtab) {
      localStorage.setItem("currentQlid", qlid);
      localStorage.setItem("topicName", topicName);
      localStorage.setItem("qLevel", qLevel);
      localStorage.setItem("currentQUlid", ulid);

      let route = this.$router.resolve({ name: "questions-reviews-overview" });
      if (newtab) {
        window.open(route.href, "_blank");
      } else this.$router.push(route.href);
    },
    loading(status) {
      this.loadingQuestions = status;
    },
    sizeChange(size) {
      this.size = parseInt(size);
    },
    getTopicName(tolid) {
      return this.topicsMeta ? this.topicsMeta[tolid]?.name : "-";
    },
    pageChange(dataSet, paginationData, res) {
      this.topicsMeta = res.info.topics;
      this.questions = dataSet;
      this.paginationData = paginationData;
      if (this.questions.length == 0) {
        this.noData = true;
      } else {
        this.noData = false;
      }
    },
  },
  mounted() {
    const sulidArray = []
    for (let i = 0; i < this.userInterest.length; i++) {
      for (let j = 0; j < dbSubjects.data.length; j++) {
        if (dbSubjects.data[j].name == this.userInterest[i].name) {
          sulidArray.push(dbSubjects.data[j])
        } 
      }
    }

    for (let i = 0; i < sulidArray.length; i++) {
      apiClient.get(`/v1/office/assessment/question/summon?size=10&offset=0&status=0&level=2048&subject=${sulidArray[i].sulid}`)
      .then(response => {
        this.questions.push(...arrayFromObject(response.data.data))
      })
      .catch(error => {
        return null
      })
    }
  }
};
</script>


<style>
.tooltip-inner {
  background-color: black !important;
  color: white;
}

.parent .tooltip-inner {
  background-color: black;
}

.tooltip.top .tooltip-arrow {
  border-top-color: black;
}

.tooltip.right .tooltip-arrow {
  border-right-color: black;
}

.tooltip.bottom .tooltip-arrow {
  border-bottom-color: black;
}

.tooltip.left .tooltip-arrow {
  border-left-color: black;
}
</style>